angular.module('LeasePilot').directive('editorControl', [
  '$rootScope',
  '$timeout',
  '$compile',
  function($rootScope, $timeout, $compile) {
    const editorControlLink = function(scope, element, attr) {
      $rootScope.$on('leaseStatusChanged', function(event, status) {
        if (status.lock) {
          scope.disableControl();
        } else {
          scope.enableControl();
        }
      });
    
      // insert control-title
      var headerKey = null;
      if (attr.for) {
        headerKey = attr.for
          .replace(/^lease/i, 'title')
          .replace('[$index]', '');
      } else {
        headerKey = '';
        console.error(`missing 'for' attribute on`, element);
      }
    
      var headerLabel = angular.element('<editor-label></editor-label>', {
        for: headerKey,
        class: 'control-title',
      });
      $compile(headerLabel)(scope).insertBefore(element);
    
      scope.enableControl = function() {
        element.removeClass('disabled');
        element.find('*').removeAttr('disabled');
      };
    
      scope.disableControl = function() {
        element.addClass('disabled');
        //disable simple input
        element.find('input').attr('disabled', true);
        //disable select
        element.find('select').attr('disabled', true);
        //disable textarea
        element.find('textarea').attr('disabled', true);
        //disable checkbox
        element.find('md-checkbox').attr('disabled', true);
        //disable radio
        element.find('md-radio-group md-radio-button').attr('disabled', true);
        //disable date
        element
          .find('lp-date-picker input, lp-date-picker button')
          .attr('disabled', true);
        //disable links
        element.find('.remove-attachment').hide();
      };
    
      scope.init = function() {
        if ($rootScope.lease && $rootScope.lease.isLocked) {
          $timeout(function() {
            scope.disableControl();
          }, 0);
        }
        $(element).addClass('editor-control');
        if (element.find('input').attr('type') === 'number' && scope.for) {
          scope.for = Number(scope.for);
        }
    
        const el = element.get(0);
        const note = document.createElement("note");
    
        if (attr.for.startsWith("lease.")) {
          note.setAttribute("category", attr.for.replace("lease.", ""));
    
          if (el.firstElementChild) {
            el.insertBefore(note, el.firstElementChild);
            $compile(note)(scope);
          }
        }
      };
      $rootScope.$on('lockLease', function(event) {
        scope.disableControl();
      });
      scope.init();
    };
    
    return {
      restrict: 'E',
      scope: {
        for: '=',
        index: '@',
        parentIndex: '@',
      },
      link: editorControlLink,
    };
  },
]);
